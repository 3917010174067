import { Injectable } from '@angular/core';
import { Meta, MetaDefinition, Title } from '@angular/platform-browser';
import { I18nService } from '@app/core/i18n.service';
import { BaseService } from '@app/shared/base/services';
import { __ } from '@app/shared/functions/object.functions';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class MetaService extends BaseService {

    constructor(
        private meta: Meta,
        private title: Title,
        private i18nService: I18nService,
        private translateService: TranslateService
    ) {
        super();
    }

    clean(): void {
        const propertyTags = [
            { name: 'og:title' },
            { name: 'og:description' },
            { name: 'og:url' },
            { name: 'og:type' },
            { name: 'og:site_name' },
            { name: 'og:image' },
            { name: 'og:image:width' },
            { name: 'og:image:height' },
            { name: 'dcterms:title' },
            { name: 'dcterms:description' },
            { name: 'dcterms:identifier' },
            { name: 'dcterms:subject' },
            { name: 'DC:title' },
            { name: 'DC:description' },
            { name: 'DC:identifier' },
            { name: 'DC:subject' }
        ];

        const nameTags = [
            { name: 'twitter:card' },
            { name: 'twitter:site' },
            { name: 'twitter:title' },
            { name: 'twitter:description' },
            { name: 'twitter:image' },
            { name: 'twitter:url' },
            { name: 'robots' },
            { name: 'language' },
            { name: 'author' },
            { name: 'publisher' },
            { name: 'company' },
            { name: 'page-topic' },
            { name: 'reply-to' },
            { name: 'distribution' },
            { name: 'revisit-after' },
            { name: 'description' },
            { name: 'keywords' }
        ];

        for (const tag of propertyTags) {
            this.meta.removeTag(`property='${tag.name}'`);
        }

        for (const tag of nameTags) {
            this.meta.removeTag(`name='${tag.name}'`);
        }
    }

    addDefaults(options?: MetaDefaultOptions): void {
        this.clean();

        options = options ?? {};

        if (!__.IsNullOrUndefined(options)) {
            const currentShortLocale = this.i18nService.shortLocaleOrNull;

            options.ogTitle = options.ogTitle ?? options.title ?? this.title.getTitle() ?? this.translateService.instant('SEO.Defaults.Title');
            options.ogDescription = options.ogDescription ?? options.description ?? this.translateService.instant('SEO.Defaults.Description');
            options.ogUrl = options.ogUrl ?? window.location.href;
            options.ogType = options.ogType ?? 'website';
            options.ogSiteName = options.ogSiteName ?? 'Soundtaxi';
            if (__.IsNullOrUndefinedOrEmpty(options.image)) {
                options.image = undefined;
            }
            options.ogImage = options.ogImage ?? options.image ?? 'https://soundtaxi.io/assets/150x150.jpg';
            options.ogImageWidth = options.ogImageWidth ?? '500';
            options.ogImageHeight = options.ogImageHeight ?? '500';
            options.dcTermsTitle = options.dcTermsTitle ?? options.title ?? this.title.getTitle() ?? this.translateService.instant('SEO.Defaults.Title');
            options.dcTermsDescription = options.dcTermsDescription ?? options.description ?? this.translateService.instant('SEO.Defaults.Description');
            options.dcTermsIdentifier = options.dcTermsIdentifier ?? window.location.href;
            options.dcTermsSubject = options.dcTermsSubject ?? options?.keywords ?? this.translateService.instant('SEO.Defaults.Keywords');
            options.dcTitle = options.dcTitle ?? options.title ?? this.title.getTitle() ?? this.translateService.instant('SEO.Defaults.Title');
            options.dcDescription = options.dcDescription ?? options.description ?? this.translateService.instant('SEO.Defaults.Description');
            options.dcIdentifier = options.dcIdentifier ?? window.location.href;
            options.dcSubject = options.dcSubject ?? options?.keywords ?? this.translateService.instant('SEO.Defaults.Keywords');
            options.twitterCard = options.twitterCard ?? 'summary';
            options.twitterSite = options.twitterSite ?? '@soundtaxi';
            options.twitterTitle = options.twitterTitle ?? options.title ?? this.title.getTitle() ?? this.translateService.instant('SEO.Defaults.Title');
            options.twitterDescription = options.twitterDescription ?? options.description ?? this.translateService.instant('SEO.Defaults.Description');
            options.twitterImage = options.twitterImage ?? options.image ?? 'https://soundtaxi.io/assets/150x150.jpg';
            options.twitterUrl = options.twitterUrl ?? window.location.href;
            options.robots = options.robots ?? 'index';
            options.language = options.language ?? currentShortLocale;
            options.author = options.author ?? 'Soundtaxi';
            options.publisher = options.publisher ?? 'Soundtaxi';
            options.company = options.company ?? 'Soundtaxi GmbH';
            options.pageTopic = options.pageTopic ?? 'Gemafreie Musik - Royalty Free Music';
            options.replyTo = options.replyTo ?? 'info@soundtaxi.com';
            options.distribution = options.distribution ?? 'global';
            options.revisitAfter = options.revisitAfter ?? '7';
            options.description = options.description ?? this.translateService.instant('SEO.Defaults.Description');
            options.keywords = options.keywords ?? this.translateService.instant('SEO.Defaults.Keywords');

            // If you add tags here, do not forget to also add them to the "clean()" method
            const propertyTags = [
                { name: 'og:title', value: options.ogTitle },
                { name: 'og:description', value: options.ogDescription },
                { name: 'og:url', value: options.ogUrl },
                { name: 'og:type', value: options.ogType },
                { name: 'og:site_name', value: options.ogSiteName },
                { name: 'og:image', value: options.ogImage },
                { name: 'og:image:width', value: options.ogImageWidth },
                { name: 'og:image:height', value: options.ogImageHeight },
                { name: 'dcterms:title', value: options.dcTermsTitle },
                { name: 'dcterms:description', value: options.dcTermsDescription },
                { name: 'dcterms:identifier', value: options.dcTermsIdentifier },
                { name: 'dcterms:subject', value: options.dcTermsSubject },
                { name: 'DC:title', value: options.dcTitle },
                { name: 'DC:description', value: options.dcDescription },
                { name: 'DC:identifier', value: options.dcIdentifier },
                { name: 'DC:subject', value: options.dcSubject },
            ];

            // If you add tags here, do not forget to also add them to the "clean()" method
            const nameTags = [
                { name: 'twitter:card', value: options.twitterCard },
                { name: 'twitter:site', value: options.twitterSite },
                { name: 'twitter:title', value: options.twitterTitle },
                { name: 'twitter:description', value: options.twitterDescription },
                { name: 'twitter:image', value: options.twitterImage },
                { name: 'twitter:url', value: options.twitterUrl },
                { name: 'robots', value: options.robots },
                { name: 'language', value: options.language },
                { name: 'author', value: options.author },
                { name: 'publisher', value: options.publisher },
                { name: 'company', value: options.company },
                { name: 'page-topic', value: options.pageTopic },
                { name: 'reply-to', value: options.replyTo },
                { name: 'distribution', value: options.distribution },
                { name: 'revisit-after', value: options.revisitAfter },
                { name: 'description', value: options.description },
                { name: 'keywords', value: options.keywords }
            ];

            for (const propertyTag of propertyTags) {
                if (!__.IsNullOrUndefined(propertyTag.value)) {
                    this.addTag({ property: propertyTag.name, content: propertyTag.value });
                }
            }

            for (const nameTag of nameTags) {
                if (!__.IsNullOrUndefined(nameTag.value)) {
                    this.addTag({ name: nameTag.name, content: nameTag.value });
                }
            }

            this.title.setTitle(options.title);
        }
    }

    add404(): HTMLMetaElement | null {
        return this.addTag({ name: 'prerender-status-code', content: '404' });
    }

    /**
     * Retrieves or creates a specific `<meta>` tag element in the current HTML document.
     * In searching for an existing tag, Angular attempts to match the `name` or `property` attribute
     * values in the provided tag definition, and verifies that all other attribute values are equal.
     * If an existing element is found, it is returned and is not modified in any way.
     * @param tag The definition of a `<meta>` element to match or create.
     * @param forceCreation True to create a new element without checking whether one already exists.
     * @returns The existing element with the same attributes and values if found,
     * the new element if no match is found, or `null` if the tag parameter is not defined.
     */
    addTag(tag: MetaDefinition, forceCreation?: boolean): HTMLMetaElement | null {
        return this.meta.addTag(tag, forceCreation);
    }

    /**
     * Retrieves or creates a set of `<meta>` tag elements in the current HTML document.
     * In searching for an existing tag, Angular attempts to match the `name` or `property` attribute
     * values in the provided tag definition, and verifies that all other attribute values are equal.
     * @param tags An array of tag definitions to match or create.
     * @param forceCreation True to create new elements without checking whether they already exist.
     * @returns The matching elements if found, or the new elements.
     */
    addTags(tags: MetaDefinition[], forceCreation?: boolean): HTMLMetaElement[] {
        return this.meta.addTags(tags, forceCreation);
    }

    /**
     * Retrieves a `<meta>` tag element in the current HTML document.
     * @param attrSelector The tag attribute and value to match against, in the format
     * `"tag_attribute='value string'"`.
     * @returns The matching element, if any.
     */
    getTag(attrSelector: string): HTMLMetaElement | null {
        return this.meta.getTag(attrSelector);
    }

    /**
     * Retrieves a set of `<meta>` tag elements in the current HTML document.
     * @param attrSelector The tag attribute and value to match against, in the format
     * `"tag_attribute='value string'"`.
     * @returns The matching elements, if any.
     */
    getTags(attrSelector: string): HTMLMetaElement[] {
        return this.meta.getTags(attrSelector);
    }

    /**
     * Modifies an existing `<meta>` tag element in the current HTML document.
     * @param tag The tag description with which to replace the existing tag content.
     * @param selector A tag attribute and value to match against, to identify
     * an existing tag. A string in the format `"tag_attribute=`value string`"`.
     * If not supplied, matches a tag with the same `name` or `property` attribute value as the
     * replacement tag.
     * @return The modified element.
     */
    updateTag(tag: MetaDefinition, selector?: string): HTMLMetaElement | null {
        return this.updateTag(tag, selector);
    }

    /**
     * Removes an existing `<meta>` tag element from the current HTML document.
     * @param attrSelector A tag attribute and value to match against, to identify
     * an existing tag. A string in the format `"tag_attribute=`value string`"`.
     */
    removeTag(attrSelector: string): void {
        return this.meta.removeTag(attrSelector);
    }

    /**
     * Removes an existing `<meta>` tag element from the current HTML document.
     * @param meta The tag definition to match against to identify an existing tag.
     */
    removeTagElement(meta: HTMLMetaElement): void {
        return this.meta.removeTagElement(meta);
    }
}

export type MetaDefaultOptions = {
    ogTitle?: string,
    ogDescription?: string,
    ogUrl?: string,
    ogType?: string,
    ogSiteName?: string,
    ogImage?: string,
    ogImageWidth?: string,
    ogImageHeight?: string,

    dcTermsTitle?: string,
    dcTermsDescription?: string,
    dcTermsIdentifier?: string,
    dcTermsSubject?: string,

    dcTitle?: string,
    dcDescription?: string,
    dcIdentifier?: string,
    dcSubject?: string,

    twitterCard?: string,
    twitterSite?: string,
    twitterTitle?: string,
    twitterDescription?: string,
    twitterImage?: string,
    twitterUrl?: string,

    robots?: string,
    language?: string,
    author?: string,
    publisher?: string,
    company?: string,
    pageTopic?: string,
    replyTo?: string,
    distribution?: string,
    revisitAfter?: string,
    description?: string,
    keywords?: string,
    image?: string,
    title?: string
};